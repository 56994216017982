module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-instagram-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"panmacmillan.co.in","short_name":"panmacmillan.co.in","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/pm_framework/assets/images/panmacmillan-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9c2bc0e2f951cbe5968e6633f2334d66"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PP7THTSD","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"gtmAuth":"bUQMru5Zil_o8MnFHeqecg","gtmPreview":"5","routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/@panmacmillan/gatsby-theme-panmacmillan/gatsby-browser.js'),
      options: {"plugins":[],"kontentProjectId":"d8dded31-d9b9-0062-03c3-c13e04fa106a","displayArticleFooterEmailSignUp":true,"disableArticlesBooksYouMayLike":true,"displayOneTrustFooterButton":false,"footerCopyrightLine":"&copy; Pan Macmillan India 2024"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
